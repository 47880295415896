var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "clients")
    ? _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-between flex-column",
                  attrs: { cols: "21", xl: "4", lg: "4" }
                },
                [
                  _c("div", { staticClass: "d-flex justify-content-start" }, [
                    _c("div", { staticClass: "d-flex flex-column ml-1" }, [
                      _c("div", { staticClass: "mb-1" }, [
                        _c("h4", { staticClass: "mb-0" }, [
                          _vm._v(
                            " " + _vm._s(_vm.clientSiteData.sitename) + " "
                          )
                        ])
                      ]),
                      _vm.$can("update", "clients")
                        ? _c(
                            "div",
                            { staticClass: "d-flex flex-wrap" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    to: {
                                      name: "apps-client-sites-edit",
                                      params: {
                                        clientname:
                                          _vm.clientSiteData.clientname,
                                        sitename: _vm.clientSiteData.sitename,
                                        clientSiteData: _vm.clientSiteData
                                      }
                                    },
                                    variant: "primary"
                                  }
                                },
                                [_vm._v(" Edit ")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ]
              ),
              _c("b-col", { attrs: { cols: "12", xl: "4", lg: "4" } }, [
                _c("table", { staticClass: "mt-2 mt-xl-0 w-100" }, [
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "pb-50" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-75",
                          attrs: { icon: "UserIcon" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Site Name")
                        ])
                      ],
                      1
                    ),
                    _c("td", { staticClass: "pb-50" }, [
                      _vm._v(" " + _vm._s(_vm.clientSiteData.sitename) + " ")
                    ])
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "pb-50" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-75",
                          attrs: { icon: "CheckIcon" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Status")
                        ])
                      ],
                      1
                    ),
                    _c("td", { staticClass: "pb-50 text-capitalize" }, [
                      _vm._v(" " + _vm._s(_vm.clientSiteData.status) + " ")
                    ])
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "pb-50" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-75",
                          attrs: { icon: "UserIcon" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Client")
                        ])
                      ],
                      1
                    ),
                    _c("td", { staticClass: "pb-50 text-capitalize" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.clientSiteData.client_display_name) +
                          " "
                      )
                    ])
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "pb-50" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-75",
                          attrs: { icon: "MapPinIcon" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Address")
                        ])
                      ],
                      1
                    ),
                    _c("td", {
                      domProps: { innerHTML: _vm._s(_vm.theAddress) }
                    })
                  ]),
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "pb-50" },
                      [
                        _c("feather-icon", {
                          staticClass: "mr-75",
                          attrs: { icon: "PhoneCallIcon" }
                        }),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Phone")
                        ])
                      ],
                      1
                    ),
                    _c("td", [
                      _vm._v(" " + _vm._s(_vm.clientSiteData.phone) + " ")
                    ])
                  ]),
                  _vm.clientSiteData.clientServices.guard === true ||
                  _vm.clientSiteData.clientServices.guardtour === true ||
                  _vm.clientSiteData.clientServices.tenant === true ||
                  _vm.clientSiteData.clientServices.privateinvestigation ===
                    true
                    ? _c("tr", [
                        _c("th", { staticClass: "pb-50" }),
                        _c("td", { staticClass: "pb-50 text-capitalize" })
                      ])
                    : _vm._e(),
                  _vm.clientSiteData.clientServices.guard === true ||
                  _vm.clientSiteData.clientServices.guardtour === true ||
                  _vm.clientSiteData.clientServices.tenant === true ||
                  _vm.clientSiteData.clientServices.privateinvestigation ===
                    true
                    ? _c("tr", [
                        _c("th", { staticClass: "pb-50" }),
                        _c("td", { staticClass: "pb-50 text-capitalize" })
                      ])
                    : _vm._e(),
                  _vm.clientSiteData.clientServices.guard === true ||
                  _vm.clientSiteData.clientServices.guardtour === true ||
                  _vm.clientSiteData.clientServices.tenant === true ||
                  _vm.clientSiteData.clientServices.privateinvestigation ===
                    true
                    ? _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "UsersIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Client Type")
                            ])
                          ],
                          1
                        ),
                        _c("td", { staticClass: "pb-50 text-capitalize" }, [
                          _vm._v(
                            " " + _vm._s(_vm.clientSiteData.client_type) + " "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.clientSiteData.clientServices.guard === true ||
                  _vm.clientSiteData.clientServices.guardtour === true ||
                  _vm.clientSiteData.clientServices.tenant === true ||
                  _vm.clientSiteData.clientServices.privateinvestigation ===
                    true
                    ? _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "GlobeIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Region")
                            ])
                          ],
                          1
                        ),
                        _c("td", { staticClass: "pb-50 text-capitalize" }, [
                          _vm._v(
                            " " + _vm._s(_vm.clientSiteData.region_loc) + " "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.clientSiteData.clientServices.guard === true ||
                  _vm.clientSiteData.clientServices.guardtour === true ||
                  _vm.clientSiteData.clientServices.tenant === true ||
                  _vm.clientSiteData.clientServices.privateinvestigation ===
                    true
                    ? _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "DollarSignIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Billing Rate")
                            ])
                          ],
                          1
                        ),
                        _c("td", { staticClass: "pb-50 text-capitalize" }, [
                          _vm._v(
                            " " + _vm._s(_vm.clientSiteData.bill_rate) + " "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.clientSiteData.clientServices.tenant === true
                    ? _c("tr", [
                        _c("th", { staticClass: "pb-50" }),
                        _c("td", { staticClass: "pb-50 text-capitalize" })
                      ])
                    : _vm._e(),
                  _vm.clientSiteData.clientServices.tenant === true
                    ? _c("tr", [
                        _c("th", { staticClass: "pb-50" }),
                        _c("td", { staticClass: "pb-50 text-capitalize" })
                      ])
                    : _vm._e(),
                  _vm.clientSiteData.clientServices.tenant === true
                    ? _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "HomeIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Tenant URL")
                            ])
                          ],
                          1
                        ),
                        _c("td", { staticClass: "pb-50 text-capitalize" }, [
                          _vm._v(
                            " " + _vm._s(_vm.clientSiteData.tenant.url) + " "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.clientSiteData.clientServices.tenant === true
                    ? _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "HomeIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Tenant Password")
                            ])
                          ],
                          1
                        ),
                        _c("td", { staticClass: "pb-50 text-capitalize" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.clientSiteData.tenant.password) +
                              " "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.clientSiteData.clientServices.tenant === true
                    ? _c("tr", [
                        _c(
                          "th",
                          { staticClass: "pb-50" },
                          [
                            _c("feather-icon", {
                              staticClass: "mr-75",
                              attrs: { icon: "HomeIcon" }
                            }),
                            _c("span", { staticClass: "font-weight-bold" }, [
                              _vm._v("Tenant Max Permits")
                            ])
                          ],
                          1
                        ),
                        _c("td", { staticClass: "pb-50 text-capitalize" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.clientSiteData.tenant.max_permits) +
                              " "
                          )
                        ])
                      ])
                    : _vm._e()
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }