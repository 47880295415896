<template>
  <b-card v-if="$can('read', 'clients')">

    <b-row>

      <!-- Client Info: Left col -->
      <b-col
        cols="21"
        xl="4"
        lg="4"
        class="d-flex justify-content-between flex-column"
      >
        <!-- Client Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ clientSiteData.sitename }}
              </h4>
            </div>
            <div
              v-if="$can('update', 'clients')"
              class="d-flex flex-wrap"
            >
              <b-button
                :to="{ name: 'apps-client-sites-edit', params: { clientname: clientSiteData.clientname, sitename: clientSiteData.sitename, clientSiteData: clientSiteData } }"
                variant="primary"
              >
                Edit
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="4"
        lg="4"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Site Name</span>
            </th>
            <td class="pb-50">
              {{ clientSiteData.sitename }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Client</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.client_display_name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Address</span>
            </th>
            <td v-html="theAddress" />
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="PhoneCallIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Phone</span>
            </th>
            <td>
              {{ clientSiteData.phone }}
            </td>
          </tr>
          <tr v-if="clientSiteData.clientServices.guard === true || clientSiteData.clientServices.guardtour === true || clientSiteData.clientServices.tenant === true || clientSiteData.clientServices.privateinvestigation === true">
            <th class="pb-50">
              &nbsp;
            </th>
            <td class="pb-50 text-capitalize">
              &nbsp;
            </td>
          </tr>
          <tr v-if="clientSiteData.clientServices.guard === true || clientSiteData.clientServices.guardtour === true || clientSiteData.clientServices.tenant === true || clientSiteData.clientServices.privateinvestigation === true">
            <th class="pb-50">
              &nbsp;
            </th>
            <td class="pb-50 text-capitalize">
              &nbsp;
            </td>
          </tr>
          <tr v-if="clientSiteData.clientServices.guard === true || clientSiteData.clientServices.guardtour === true || clientSiteData.clientServices.tenant === true || clientSiteData.clientServices.privateinvestigation === true">
            <th class="pb-50">
              <feather-icon
                icon="UsersIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Client Type</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.client_type }}
            </td>
          </tr>
          <tr v-if="clientSiteData.clientServices.guard === true || clientSiteData.clientServices.guardtour === true || clientSiteData.clientServices.tenant === true || clientSiteData.clientServices.privateinvestigation === true">
            <th class="pb-50">
              <feather-icon
                icon="GlobeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Region</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.region_loc }}
            </td>
          </tr>
          <tr v-if="clientSiteData.clientServices.guard === true || clientSiteData.clientServices.guardtour === true || clientSiteData.clientServices.tenant === true || clientSiteData.clientServices.privateinvestigation === true">
            <th class="pb-50">
              <feather-icon
                icon="DollarSignIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Billing Rate</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.bill_rate }}
            </td>
          </tr>
          <tr v-if="clientSiteData.clientServices.tenant === true">
            <th class="pb-50">
              &nbsp;
            </th>
            <td class="pb-50 text-capitalize">
              &nbsp;
            </td>
          </tr>
          <tr v-if="clientSiteData.clientServices.tenant === true">
            <th class="pb-50">
              &nbsp;
            </th>
            <td class="pb-50 text-capitalize">
              &nbsp;
            </td>
          </tr>
          <tr v-if="clientSiteData.clientServices.tenant === true">
            <th class="pb-50">
              <feather-icon
                icon="HomeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Tenant URL</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.tenant.url }}
            </td>
          </tr>
          <tr v-if="clientSiteData.clientServices.tenant === true">
            <th class="pb-50">
              <feather-icon
                icon="HomeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Tenant Password</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.tenant.password }}
            </td>
          </tr>
          <tr v-if="clientSiteData.clientServices.tenant === true">
            <th class="pb-50">
              <feather-icon
                icon="HomeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Tenant Max Permits</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ clientSiteData.tenant.max_permits }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BRow, BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, BRow, BCol,
  },
  props: {
    clientSiteData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    let theAddress = ''
    theAddress = `${props.clientSiteData.address}`
    if (props.clientSiteData.addressUnit !== null && props.clientSiteData.addressUnit !== '') {
      theAddress = `${theAddress}, Unit ${props.clientSiteData.addressUnit}<br>`
    } else {
      theAddress = `${theAddress}<br>`
    }
    if (props.clientSiteData.province !== null && props.clientSiteData.province !== '') {
      theAddress = `${theAddress}${props.clientSiteData.province}`
      if (props.clientSiteData.country !== null && props.clientSiteData.country !== '') {
        theAddress = `${theAddress}, `
      }
    }
    if (props.clientSiteData.country !== null && props.clientSiteData.country !== '') {
      theAddress = `${theAddress}${props.clientSiteData.country}`
    }
    if (props.clientSiteData.postal !== null && props.clientSiteData.postal !== '') {
      theAddress = `${theAddress}<br>${props.clientSiteData.postal}`
    }

    return {
      theAddress,
    }
  },
}
</script>

<style>

</style>
