var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "clients")
    ? _c(
        "b-card",
        [
          _c(
            "b-row",
            [
              _c("b-col", [
                _c("div", [
                  _c("div", [
                    _c("div", { staticClass: "mb-1" }, [
                      _c("h4", { staticClass: "mb-0" }, [
                        _vm._v(" Site Location ")
                      ])
                    ]),
                    _c(
                      "div",
                      [
                        _c(
                          "l-map",
                          { attrs: { zoom: _vm.zoom, center: _vm.center } },
                          [
                            _c("l-tile-layer", { attrs: { url: _vm.url } }),
                            _c("l-marker", {
                              attrs: { "lat-lng": _vm.markerLatLng }
                            }),
                            _c("l-circle", {
                              attrs: {
                                "lat-lng": _vm.circle.center,
                                radius: _vm.circle.radius,
                                color: _vm.circle.color
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }